import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_comsuk.svg'
import InterestForm from '../components/InterestForm'
import { GatsbySeo } from 'gatsby-plugin-next-seo'


export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
    <GatsbySeo title='PanSurg COMS-UK'
          description='Impact of COVID-19 on Obesity Management Services in the United Kingdom (COMS-UK).'/>
      <Hero title="PanSurg COMS-UK"
            subtitle="Impact of COVID-19 on Obesity Management Services in the United Kingdom (COMS-UK)"
            fluid={data.hero.childImageSharp.fluid}
            buttonTxt = "Participate"
            buttonLink= "https://www.surveymonkey.co.uk/r/COMS--UK"
            breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'COMS-UK'
        />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

      <div className="has-text-centered">
        <img src={logo} width="400px" alt="COMS-UK Logo" style={{marginBottom:'2rem'}}/>
      </div>

      <p className="subtitle">Many thanks to all our collaborators on this study. We are now closed to
      further recruitment and will be publishing our results soon.</p>


      <div className="content">

        <p>COMS-UK is a nationwide cross-sectional questionnaire to comprehend the varying
        disruption of Bariatric multidisciplinary services by the COVID-19 pandemic.</p>

        <p>COMS-UK is for all healthcare professionals who are involved in the care of bariatric patients
        (surgeons, dieticians, psychiatrists, psychologists, nurse specialists and physicians)
        and based in the United Kingdom. We aim to understand the diversified impact
        COVID-19 has had on bariatric units nationwide and consider expert opinions within each discipline.</p>

        <p>If you are involved in the management of severe and complex obese patients, we would
        be tremendously grateful for your time to complete this brief questionnaire.</p>

        <p>Results will be published online via the PanSurg platform.</p>
      </div>


      </div>
    </div>
  </section>



  </>
  )
}
